import * as React from 'react';

import { useDispatch } from 'react-redux';
import { setUpdatingData } from '../../redux/admin/adminSlice';

import { AiFillCaretDown } from 'react-icons/ai';
import DataService from '../../services/dataService';

import  { Button } from '@mui/material';

import DeleteForeverIcon from '@mui/icons-material/DeleteForever';

import SectionsEditLayout from './SectionEditLayout';

import { GridLoader } from 'react-spinners';

const SettingCategory = (props) => (
    <div
        style={{
            display: 'flex',
            flexDirection: 'column',
            position: 'relative'
        }}
    >
        <h2 style={{ marginBottom: 0 }}>{props.categoryName}</h2>
        {props.children}
    </div>
)

const SettingCategoryRow = (props) => (
    <div 
        style={{
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'flex-start',
            flexWrap: 'wrap'
        }}
    >
        {props.children}
    </div>
)

const SectionsListItem = ({ 
    sectionId, 
    PageId, 
    pageNames, 
    posttypes, 
    sectionIds, 
    togglePostListDisplay, 
    draggableRef, 
    handleRemoveSection,
    siteSettings,
    forms,
    siteId
}) => {

    const dispatch = useDispatch();

    const [selected, setSelected] = React.useState(false);
    const [stylesObject, setStyles] = React.useState({});
    const [settings, setSettings] = React.useState()
    const [settingIds, setSettingIds] = React.useState([]);
    const [posts, setPosts] = React.useState([]);
    const [section, setSection] = React.useState({});
    const [secIds, setSecIds] = React.useState(null);
    const [EditLayout, setEditLayout] = React.useState(<div style={{ display: 'none' }} />);
    const [passForms, setPassForms] = React.useState(false);
    const editor = React.useRef(null);

    React.useEffect(() => {
        ( async () => {
            
            // fetch information from DatoCMS
            let section = await DataService.getRecordHTTP(sectionId).then(res => res.data.attributes);
            setSection(section)
            setSettings(JSON.parse(section.settings));
            setPassForms(section.name.includes('Form'));

            // get edit layout
            const editLayout = await React.lazy(() => import(`../../sections/${section.category}/${section.name.split(' ').join('')}/Edit${section.name.split(' ').join('')}`).catch(err => console.log(err.message)))

            setEditLayout(editLayout);

            setStyles(stylesObject);

        })();
        
    }, []);

    const deleteSection = (secId) => {

        dispatch(setUpdatingData({ updatingData: true }));

        DataService.deleteRecord(secId)
            .then( _ => {

                let newSectionIds = sectionIds;

                newSectionIds.splice(sectionIds.indexOf(secId));

                DataService.updateRecord(PageId, {
                    sections: newSectionIds
                });

                setSecIds(newSectionIds);

                dispatch(setUpdatingData({ updatingData: false }));
            })
            .then( _ => {} );
    }

    return (
        <div style={{ width: '100%', padding: 2 }}>
            <div style={{ 
                width: '100%',
                backgroundColor: '#133c55', 
                height: '3rem', 
                display: 'flex', 
                flexDirection: 'row', 
                alignItems: 'center', 
                margin: '0rem',
                borderRadius: '0.2rem',
                justifyContent: 'space-between'
            }}>
            <span 
                onClick={() => {
                    setSelected(!selected);
                    togglePostListDisplay(posts);
                }}
                style={{ 
                    paddingLeft: '1rem',
                    width: '100%',
                    backgroundColor: '#45425a', 
                    height: '3rem', 
                    display: 'flex', 
                    flexDirection: 'row', 
                    alignItems: 'center', 
                    borderRadius: '0.2rem',
                    justifyContent: 'flex-end'
                }}>
                
                <p className="text-lg" style={{ width: '100%', color: 'whitesmoke', fontFamily: 'monospace' }}>{section.name}</p>
                <Button style={{ backgroundColor: 'red', width: 10}} size="small" variant="contained" 
                    onClick={() => {
                        let sure = window.confirm("Are you sure you want to delete this section?");

                        if (sure) {
                            deleteSection(sectionId)
                            handleRemoveSection(sectionId)
                        }
                    }} >
                        <DeleteForeverIcon  />
                </Button>
                <div  className='clickableDiv' style={{ display: 'block' }}>
                    <AiFillCaretDown
                        style={{
                            float: 'right',
                            padding: 10,
                            pointerEvents: 'bounding-box'
                        }}
                    />
                </div>
            </span>
            </div>

            {
                selected && (
                    <React.Suspense fallback={<div style={{ backgroundColor: 'white', border: '1px-solid-black', margin: 'auto' }} className='p-4'><GridLoader color='green' className='m-auto' /></div>}>

                        
                        <SectionsEditLayout>
                            <EditLayout 
                                settings={settings} 
                                sectionId={sectionId}
                                pageNames={pageNames}
                                posttypes={posttypes}
                                SettingCategory={SettingCategory}
                                SettingCategoryRow={SettingCategoryRow} 
                                draggableRef={draggableRef}
                                siteSettings={JSON.parse(siteSettings)}
                                forms={passForms ? forms : []}
                                siteId={siteId}
                            />
                        </SectionsEditLayout>
                    </React.Suspense>
                )
            }

        </div>
    )
}

export default SectionsListItem;