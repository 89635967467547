
// base class
class Section {

    returnAllSectionMetas = () => {

        let category = this.constructor.name;
        let sectionGetters = Object.getOwnPropertyNames(this).filter( prop => typeof this[prop] == "function");
        
        let sections = sectionGetters.map( 
            getter => { 
                if (getter !== 'returnAllSectionMetas') return this[getter](); 
            })
            .filter( section => section !== undefined)

        sections.forEach( section => section['category'] = category);
        
        return sections;
    }
}


// section category classes
class Menus extends Section {

    simpleMenu = () => {
        return { 
            name: 'Simple Menu', 
            menus: [], 
            settings: {
                backgorundColor: 'black',
                title: {
                    value: 'Title',
                    font: 'sarif',
                    color: 'black',
                },
                menus: {
                    main: [
                        {
                            title: 'Home',
                            type: 'url',
                            url: '/home',
                        }
                    ]
                },

            },
            posts: null
        }
    }
}


class Overlays extends Section {

    ImageWithOverlay = () => {
        return { 
            name: 'Image With Overlay',
            menus: [],
            settings: {
                backgroundColor: 'yellow',
                color: 'black'
            }
        };
    }
};

class Template extends Section {

    PageWrapper = () => {
        return {
            name: "Page Wrapper",
            settings: {}
        }
    }
}

class Posts extends Section {

    SimpleMultiPostRows = () => {
        return {
            name: "Simple Multi Post Rows",
            settings: {
                backgroundColor: 'whitesmoke',
                sectionTitle: {
                    value: '',
                    fontFamily: 'sarif',
                    color: 'black',
                    size: 12
                },
                posttype: 'all'
            }
        }
    }

    PageArticle = () => {
        return {
            name: "Page Article",
            settings: {
                backgroundColor: 'black',
                pageColor: 'whitesmoke',
                fontColor: 'black',
                fontFamily: 'sarif'
            }
        }
    }

    PostRowAndFeature = () => {
        return {
            name: "Post Row And Feature",
            settings: {
                sectionTitle: "",
                postType: 'all',
                backgroundColor: 'whitesmoke',
                title: {
                    color: 'black'
                },
                snippet: {
                    color: 'black'
                }
            }
        }
    }
}

class Forms extends Section {

    SimpleForm = () => {
        return {
            name: "Simple Form",
            settings: {
                backgroundColor: 'whitesmoke',
                sectionHeading: {
                    value: 'Heading',
                    fontFamily: null,
                    size: null,
                    color: 'black'
                },
                chosenForm: '',
                formAlignment: '',
                formStyle: 'default'
            }
        }
    }
}

class Quotes extends Section {

    InlineHeadingAndQuote = () => {
        return {
            name: "Inline Heading And Quote",
            settings: {
                backgroundColor: 'whitesmoke',
                heading: {
                    value: 'Heading',
                    fontFamily: null,
                    size: null,
                    color: 'black'
                },
                reversed: false,
                quote: {
                    value: '',
                    fontFamily: null,
                    size: null,
                    color: 'gray'
                }
            }
        }
    }
}

class Footers extends Section {

    SimpleCenteredFooter = () => {
        return {
            name: "Simple Centered Footer",
            settings: {
                backgroundColor: 'whitesmoke',
                links: [{
                    title: 'Home',
                    type: 'page',
                    url: '/home'
                }],
                linkFontFamily: 'sarif',
                linkColor: 'black',
                menus: {},
                showSocial: true,
                lightIcons: true,
                closingText: '&copy; <Your Company Here>, All rights reserved'
            }
        }
    }

    FourColumnFooter = () => {
        return {
            name: "Four Column Footer",
            settings: {
                backgroundColor: 'whitesmoke',
                menuHeadingColor: 'grey',
                menuItemColor: 'lightgrey',
                menus: {},
                showNewsletterForm: true,
                copyrightText: {value: '© 2020 Workflow, Inc. All rights reserved.'},
                showSocial: true,
                titles: {}
            }
        }
    }
}

class Contact extends Section {

    SplitBrandPanelForm = () => {
        return {
            name: "Split Brand Panel Form",
            settings: {
                formId: null,
                submitButtonText: 'Submit',
                formTitle: 'Send Us A Message',
                sidePanelText: 'Contact Information',
                sitePanelDescriptionText: 'Nullam risus blandit ac aliquam justo ipsum. Quam mauris volutpat massa dictumst amet. Sapien tortor lacus arcu.',
                tel: '+1 (555) 000-0000',
                email: 'support@example.com',
                showSocial: true,
                backgroundColor: 'whitesmoke',
                secondaryColor: 'lightblue',
                reverseOrientation: false
            }
        }
    }
}

class Content extends Section {

    SimpleTextContent = () => {
        return {
            name: "Simple Text Content",
            settings: {
                title: { value: 'title' },
                subtitle: { value: 'subtitle' },
                content: { value: 'Lorem Ipsum is simply dummy text of the printing and typesetting industry.' },
                backgroundContent: 'whitesmoke'
            }
        }
    }

    TwoColumnsWithImage = () => {
        return {
            name: "Two Columns With Image",
            settings: {
                title: {value: 'title'},
                subtitle: {value: 'subtitle'},
                content: {value: 'Lorem Ipsum is simply dummy text of the printing and typesetting industry.'},
                imageId: '',
                backgorundColor: 'whitesmoke'
            }
        }
    }

    SplitWithImage = () => {
        return {
            name: "Split With Image",
            settings: {
                title: { value: 'Title' },
                subtitle: { value: 'Subtitle' },
                content: '<p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Et, egestas tempus tellus etiam sed</p>',
                backgorundColor: 'whitesmoke',
                foregroundColor: 'darkgrey',
                overlayColor: 'darkgrey',
                reversed: false,
                imageId: '',
                showButton: true,
                buttonText: 'Click the Button'
            }
        }
    }

    BackgroundImageWithOverlappingCards = () => {
        return {
            name: "Background Image With Overlapping Cards",
            settings: {
                title: { value: 'title' },
                content: { value: 'Lorem Ipsum is simply dummy text of the printing and typesetting industry.' },
                backgroundColor: 'whitesmoke',
                opacity: 0,
                cards: {
                    card1: {
                        title: ''
                    },
                    card2: {
                        title: ''
                    },
                    card3: {
                        title: ''
                    }
                },
                cardColor: 'lightblue',
                imageOverlayColor: 'lightblue'
            }
        }
    }
}

class Hero extends Section {

    AlignedTextHero = () => {
        return {
            name: "Aligned Text Hero",
            settings: {
                iconImage: '',
                title: {value: 'Title text'},
                subtitle: {value: 'subtitle text'},
                content: '<p>Content Body</p>',
                menus: {},
                showSocial: false,
                showMenuRightButton: false,
                textAlignment: 'center',
                button1Text: 'click the button',
                button2Text: 'click the button',
                button1Active: false,
                button2Active: false,
                showBodyButtons: false,
                backgroundImage: ''
            }
        }
    }
}

class Feature extends Section {

    GridList = () => {
        return {
            name: "Grid List",
            settings: {
                backgroundColor: 'whitesmoke',
                foregroundColor: 'grey',
                title: 'title',
                subtitle: 'subtitle',
                features: []
            }
        }
    } 
}

class Stats extends Section {

    CardStats = () => {
        return {
            name: 'Card Stats',
            settings: {
                title: 'Title',
                secondaryColor: 'Lorem ipsum dolor, sit amet consectetur adipisicing elit. Repellendus repellat laudantium.',
                backgorundColor: 'whitesmoke',
                foregroundColor: 'black',
                secondaryColor: 'grey',
                stats: []
            }
        }
    }
}

export {
    Menus,
    Overlays,
    Template,
    Posts,
    Quotes,
    Forms,
    Footers,
    Contact,
    Content,
    Hero,
    Feature,
    Stats
};

