import * as React from 'react';

import  Paper  from '@mui/material/Paper'



const SectionEditLayout = (props) => {

    return (
        <Paper>
            <div style={{ width: '90%', padding: '2rem' }}>
                {props.children}
            </div>
        </Paper>
    )
}

export default SectionEditLayout;
