import { graphql, navigate } from 'gatsby';
import * as React from 'react';
import Layout from './Layout';



import DataService from '../../services/dataService';

import SectionsListItem from '../../components/pages/sectionsListItem';

import * as SectionClasses from '../../components/meta';

// components
import {
    Divider, FormControlLabel, IconButton, MenuItem, Select, Switch, Tooltip
} from '@mui/material';

import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd';

import { useDispatch, useSelector } from 'react-redux';
import { setUpdatingData } from '../../redux/admin/adminSlice';

// icons
import AddIcon from '@mui/icons-material/Add';
import DeleteForeverOutlined from '@mui/icons-material/DeleteForeverOutlined';
 


const useFetching = (someFetchActionCreator, data) => {
    const dispatch = useDispatch();
   
    React.useEffect(() => {
      dispatch( someFetchActionCreator( data ) )
    }, []);
  
}

const Sections = ({ data, location, pageContext }) => {

    // useFetching(setSitePagesState, { sitePages: location.state.sitePages });

    // console.log(location);

    const pageNames = pageContext.pageNames;
    // console.log('pageContent: ' + JSON.stringify(pageContext));

    const sectionsDragDisabled  = useSelector((state) => state.admin.sectionsDragDisabled );
    const dispatch = useDispatch();

    const [pageSections, setPageSections] = React.useState([]);
    const [sectionIds, setSectionIds] = React.useState(null);
    const [pageName, setPageName] = React.useState(null);

    const [OverlayDisplay, setOverlayDisplay] = React.useState(null);
    const [displayPostList, setDisplayPostList] = React.useState(false);

    const [sections, setSections] = React.useState([]);
    const [postTitles, setPostTitles] = React.useState([]);
    const [PageId, setPageId] = React.useState(location.state ? location.state.PageId : localStorage.getItem('PageId'));
    const [draggableRefs, setDraggableRefs] = React.useState([]);

    const [templatePages, setTemplatePages] = React.useState(location.state ? location.state.templates : JSON.parse(localStorage.getItem('templatePages')));
    const [isTemplate, setIsTemplate] = React.useState(location.state?.isTemplate);
    const [currentTemplate, setCurrentTemplate] = React.useState(location.state ? location.state.currentTemplate?.id : JSON.parse(localStorage.getItem('currentTemplate'))?.id)

    const createDraggableRefs = React.useCallback((sections) => {
        setDraggableRefs(
            sections.map( _ => React.createRef() )
        );
    })

    React.useEffect(() => {
 
        // console.log(JSON.parse(localStorage.getItem('templatePages')))

        if (location.state) {

            localStorage.setItem('PageId', location.state?.PageId)
            localStorage.setItem('templatePages', JSON.stringify(location.state?.templates))
            localStorage.setItem('isTemplate', location.state?.isTemplate ? 'true' : 'false')
            localStorage.setItem('currentTemplate', JSON.stringify(location.state?.currentTemplate));
        } else {
            setTemplatePages(JSON.parse(localStorage.getItem('templatePages')));
            setCurrentTemplate(JSON.parse(localStorage.getItem('currentTemplate'))?.id)
        }

        (async () => {
            // console.log(JSON.parse(localStorage.getItem('templatePages')))
            getAllSections();
            await getPageSections();
        })();


    }, []);

    React.useEffect(() => {
        // console.log(location.state.templates);
    }, [])


    const updateSections = (section) => {

        let newSections = [...pageSections];
        newSections.push(section.data.id);
        setPageSections(newSections);
        setOverlayDisplay(null);
    }

    const getPageSections = async () => {

        dispatch(setUpdatingData({ updatingData: true }));

        DataService.getRecord(PageId)
            .then( page => {

                setPageSections(page.sections);
                setSectionIds(page.sections.map( section => section.id ));
                setPageName(page.name)
                setIsTemplate(page.isTemplate)
                
                let currentTemplate = location.state.templates.filter( template => template.id.split('-')[1] == page.template )[0];
                setCurrentTemplate(currentTemplate?.id);
 
                createDraggableRefs(page.sections); 

                dispatch(setUpdatingData({ updatingData: false }));
            })
            .catch(err => dispatch(setUpdatingData({ updatingData: false })))
    }

    const updateSectionOrder = async (result) => {

        dispatch(setUpdatingData({ updatingData: true }));

        const reorder = (list, startIndex, endIndex) => {
            const result = Array.from(list);
            const [removed] = result.splice(startIndex, 1);
            result.splice(endIndex, 0, removed);
          
            return result;
        };

        const newSectionsOrder = reorder(
            pageSections,
            result.source.index,
            result.destination.index
        );

        DataService.updateRecord(PageId.split('-')[1], {
            sections: newSectionsOrder
        }).then( _ => {
            setPageSections(newSectionsOrder);
            dispatch(setUpdatingData({ updatingData: false }));
        });
    }

    const updateTemplate = (templateId) => {

        dispatch(setUpdatingData({ updatingData: true }));

        DataService.updateRecord(PageId, {
            template: templateId.split('-')[1]
        }).then(res => {
            setCurrentTemplate(templateId);
            dispatch(setUpdatingData({ updatingData: false }));
        })
    }

    const toggleIsTemplate = () => {
        
        dispatch(setUpdatingData({ updatingData: true }));

        if (isTemplate) {

            const confirm = window.confirm("Are you sure?");

            if (confirm) {
                
                setIsTemplate(!isTemplate);
                DataService.updateRecord(PageId, {
                    is_template: false
                });
            }

            return;
        }
        

        setIsTemplate(!isTemplate);
        DataService.updateRecord(PageId, {
            is_template: true
        }).then( _ => dispatch(setUpdatingData({ updatingData: false })));
        
    }

    const updatePageTemplate = (template) => {

        DataService.updateRecord(PageId, {
            template
        })
    }

    const getAllSections = async () => {

        let sectionsObject = {};
        Object.keys(SectionClasses).forEach( category => {
            const cls = SectionClasses[category];
            let clsInstance = new cls();
            let categorySections = clsInstance.returnAllSectionMetas();
            sectionsObject[category] = categorySections;
        });

        setSections(sectionsObject);

    }

    const togglePostListDisplay = (posts) => {
        if (posts.length) setDisplayPostList(!displayPostList);
        console.log(posts.map( post => post.title ));
        if (!displayPostList) setPostTitles(posts.map( post => post.title ));
        else {
            setPostTitles([]);
        }
    }
    
    const openOverlayTool = () => {
        let overlay =  React.lazy(() => import('../../components/overlay/overlay').catch(() => <div style={{ display: 'none' }}></div>))
        setOverlayDisplay(overlay);
    }

    const closeOverlayTool = () => {
        setOverlayDisplay(null);
        // unmountComponentAtNode(document.getElementById('main-admin-overlay'))
    }

    const deletePage = () => {
        
        const confirm = window.confirm('Are you sure you want to delete this page?')

        if (confirm) {
            dispatch(setUpdatingData({ updatingData: true }));

            DataService.deleteRecord(PageId)
                .then( success => {

                    navigate('/admin/pages', {
                        state: {
                            from: 'sections'
                        }
                    })
                })

            dispatch(setUpdatingData({ updatingData: false }));
        }
    }

    const handleRemoveSection = (section) => {

        const sectionIndex = pageSections.indexOf(section);
        const newPageSections = [...pageSections];
        newPageSections.splice(sectionIndex, 1);
        setPageSections(newPageSections);
    }

    return (
        <Layout>
            {
                OverlayDisplay != null && (
                    <React.Suspense fallback={<div style={{ display: 'none' }}></div>}>
                        <OverlayDisplay 
                            type="sections" 
                            closeOverlayTool={closeOverlayTool} 
                            siteId={data.datoCmsWebsite.id} 
                            PageId={PageId}
                            iterables={sections} 
                            sectionIds={pageSections}
                            updateSections={updateSections}
                        />
                    </React.Suspense>
                )
            }
            <div style={{ padding: '2rem', height: '100%' }}>
                <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', width: '100%', verticalAlign: 'center' }}>
                    <h2 className="text-center font-semibold text-2xl flex-1 border-b border-b-gray-300 mb-8 pb-1" style={{ color: '#45425a', fontFamily: 'monospace' }}>{pageName} Page Sections</h2>

                    <Divider />

                    <Tooltip 
                      title={"Delete Page"} 
                      placement='left-start'
                      className="hi"
                      style={{
                          position: 'fixed',
                          bottom: 40,
                          right: 160,
                          backgroundColor: 'darkred',
                          width: 50,
                          height: 50,
                          WebkitBoxShadow: '1px 2px 14px 1px rgba(0,0,0,0.80)',
                          boxShadow: '1px 2px 14px 1px rgba(0,0,0,0.80)',
                          zIndex: 2
                      }}
                  >
                      <IconButton 
                          onClick={() => deletePage() }
                      >
                          <DeleteForeverOutlined style={{ textAlign: 'right', fontSize: 30, color: 'whitesmoke' }} />
                      </IconButton>
                  </Tooltip>
                  <Tooltip 
                      title={"Add Section"} 
                      placement='left-start'
                      className="hi"
                      style={{
                          position: 'fixed',
                          bottom: 40,
                          right: 40,
                          backgroundColor: '#45425a',
                          width: 100,
                          height: 100,
                          WebkitBoxShadow: '1px 2px 14px 1px rgba(0,0,0,0.80)',
                          boxShadow: '1px 2px 14px 1px rgba(0,0,0,0.80)',
                          zIndex: 2
                      }}
                  >
                      <IconButton 
                          onClick={() => openOverlayTool() }
                      >
                          <AddIcon style={{ textAlign: 'right', fontSize: 50, color: 'whitesmoke' }} />
                      </IconButton>
                  </Tooltip>
                </div>
                <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
                    <FormControlLabel 
                        control={<Switch checked={isTemplate} onChange={toggleIsTemplate} />}
                        label="Is Template"
                    />
                    {!isTemplate && (
                        <Select 
                            value={currentTemplate}
                            disabled={isTemplate}
                            onChange={(e) => {
                                updateTemplate(e.target.value);
                            }}
                            defaultValue={currentTemplate !== undefined ? currentTemplate : 'None'}
                        >
                            <MenuItem index={'zero'} value={"None"}>None</MenuItem>
                            {
                                templatePages?.map( (template, index) => (
                                    <MenuItem key={index} value={template.id}>
                                        {template.name}
                                    </MenuItem>
                                ))
                            }
                        </Select>
                    )}
                </div>
                <div
                    className="w-full m-1 mt-10 justify-end inline-block"
                    style={{ 
                        height: '3rem', 
                        borderRadius: '0.2rem'
                    }}
                >
                    <DragDropContext
                        onDragUpdate={(result, provided) => {
                            updateSectionOrder(result);
                        }}
                    >
                        <Droppable droppableId="sections" renderClone={null}>
                                {(provided) => (
                                    <ul 
                                        style={{ listStyle: 'none' }} 
                                        className="sections" 
                                        {...provided.placeholder} 
                                        {...provided.droppableProps} 
                                        ref={provided.innerRef}
                                    >
                                        {
                                            pageSections.map( (sectionId, index) => (
                                                <Draggable 
                                                    isDragDisabled={sectionsDragDisabled}
                                                    key={sectionId}
                                                    ref={draggableRefs[index]}
                                                    draggableId={String(sectionId)} 
                                                    index={index}
                                                    onClick={(e) => console.log(e)}
                                                >
                                                    {(provided) => (
                                                        <li
                                                            ref={provided.innerRef} 
                                                            {...provided.draggableProps} 
                                                            {...provided.dragHandleProps}
                                                        >
                                                            <SectionsListItem
                                                                key={sectionId}
                                                                sectionId={sectionId} 
                                                                PageId={PageId} 
                                                                pageNames={pageNames}
                                                                posttypes={data.allDatoCmsPost.distinct}
                                                                forms={data.allDatoCmsForm.nodes}
                                                                sectionIds={pageSections}
                                                                togglePostListDisplay={togglePostListDisplay}
                                                                draggableRef={draggableRefs[index]}
                                                                handleRemoveSection={handleRemoveSection}
                                                                siteSettings={data.datoCmsWebsite.settings}
                                                                siteId={data.datoCmsWebsite.id}
                                                            />
                                                        </li>
                                                    )}
                                                </Draggable>
                                            ))
                                        }
                                    </ul>
                                )}
                        </Droppable>
                    </DragDropContext>
                </div>
            </div>
        </Layout>
    )
}

export const query = graphql`
    query FormsAndPostTypeData($sitename: String) {
        allDatoCmsPost(filter: {site: {name: {eq: $sitename }}}) {
            distinct(field: posttype)
        }
        allDatoCmsForm(filter: {site: {name: {eq: $sitename}}}) {
            nodes {
              title
              id
            }
          }
        
        datoCmsWebsite(name: {eq: $sitename}) {
            id
            settings
        }
    }
`

export default Sections;